.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: url(/images/btn-gray.png) no-repeat !important;
  background-size: 100% 100% !important;
  font-size: tovw(24);
  text-transform: uppercase;
  color: #161349 !important;
  height: tovw(56);
  line-height: tovw(56);
  padding: 0 tovw(15);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  min-width: tovw(255);
  position: relative;
  font-weight: bold;
  transition: transform 0.25s ease;
  font-family: $fifa;
  &--spin {
    background: url(/images/btn-red-big.png) no-repeat !important;
    background-size: 100% 100% !important;
    font-size: tovw(36);
    font-weight: bold;
    height: tovw(76);
    line-height: tovw(76);
    color: #fff !important;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &--fw {
    width: 100%;
    img {
      width: tovw(21);
      margin-top: tovw(-3);
    }
  }
  &--purple {
    background: url(/images/btn-confirm.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
    min-width: tovw(200);
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &--cancel {
    background: url(/images/btn-cancel.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
    min-width: tovw(200);
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &--share {
    background: url(/images/btn-share.png) no-repeat !important;
    background-size: 100% 100% !important;
    filter: drop-shadow(2px 2px 3px rgba(84, 84, 84, 0.52));
    height: tovw(64);
    line-height: tovw(64);
    color: #fff !important;
  }
  &--team {
    background: url(/images/btn-team.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #161349 !important;
  }
  &--white {
    background: url(/images/btn-white.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &--black {
    background: url(/images/btn-black.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &--green {
    background: url(/images/btn-orange.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &--orange {
    background: url(/images/btn-orange.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &--border {
    background-image: none !important;
    color: #000000 !important;
    font-weight: 500;
    span {
      font-weight: bold;
      font-size: tovw(30);
      color: #000000 !important;
    }
  }
  &--match {
    width: tovw(176);
    height: tovw(56);
    line-height: tovw(56);
    min-width: initial !important;
    text-align: center;
  }
  &--predict {
    background: url(/images/btn-predict.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #000000 !important;
  }
  &--predicted {
    background: url(/images/btn-predicted.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
  }
  &--ended {
    background: url(/images/btn-ended.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
  }
  @include min-screen(1200px) {
    &:hover {
      transform: translateY(-0.5rem);
    }
  }
  &--normal {
    background: #fff !important;
    border: 1px solid #161349 !important;
    border-radius: tovw(32);
    height: tovw(32);
    line-height: tovw(32);
    text-transform: none;
    font-weight: normal;
    color: #161349 !important;
    min-width: initial !important;
    font-size: tovw(18) !important;
    padding: 0 !important;
    &--active {
      color: #161349 !important;
      border-color: #ffab44 !important;
      background: #ffab44 !important;
    }
  }
}
.swal2-container .swal2-popup .swal2-styled.swal2-confirm {
  background: url(/images/btn-confirm.png) no-repeat !important;
  background-size: 100% 100% !important;
  color: #fff !important;
  text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
    -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
    -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  min-width: tovw(200);
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  // @extend %grayscale;
  background: url(/images/btn-cancel.png) no-repeat !important;
  background-size: 100% 100% !important;
  color: #fff !important;
  min-width: tovw(200);
  text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
    -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
    -1px 0px 0 #d50909, 0px -1px 0 #d50909;
}
