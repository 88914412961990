/// Variables
/// @see https://www.smashingmagazine.com/2016/01/variables-in-css-architecture/

/// Grid
/// Using rem-units to scale for accessibility (based on the user's browser settings, default 1rem equals 16px)
/// @see layout/grid
/// grid class specified below ($breakpoints)

$grid-max-width: 120rem;
$grid-gutter: 1rem;
$grid-columns: 12;
$grid-show-outlines: false; //to show the grid's outlines

/// Breakpoints - used by the grid and media query manager
/// @type Map
/// @prop {size}: [min-width] - breakpoint name and minimum width for media queries
$breakpoints: (
  "xs": 0,
  "sm": 420px,
  "md": 720px,
  "lg": 960px,
  "xl": 1200px,
);

@font-face {
  font-family: "UTM HelvetIns";
  src: url("/fonts/UTMHelvetIns.eot");
  src: url("/fonts/UTMHelvetIns.eot?#iefix") format("embedded-opentype"),
    url("/fonts/UTMHelvetIns.woff2") format("woff2"),
    url("/fonts/UTMHelvetIns.woff") format("woff"),
    url("/fonts/UTMHelvetIns.ttf") format("truetype"),
    url("/fonts/UTMHelvetIns.svg#UTMHelvetIns") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FIFAnybody Condensed";
  src: url("/fonts/FIFAnybody-CondensedMedium.eot");
  src: url("/fonts/FIFAnybody-CondensedMedium.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/FIFAnybody-CondensedMedium.woff2") format("woff2"),
    url("/fonts/FIFAnybody-CondensedMedium.woff") format("woff"),
    url("/fonts/FIFAnybody-CondensedMedium.ttf") format("truetype"),
    url("/fonts/FIFAnybody-CondensedMedium.svg#FIFAnybody-CondensedMedium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FIFAnybody Condensed";
  src: url("/fonts/FIFAnybody-CondensedRegular.eot");
  src: url("/fonts/FIFAnybody-CondensedRegular.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/FIFAnybody-CondensedRegular.woff2") format("woff2"),
    url("/fonts/FIFAnybody-CondensedRegular.woff") format("woff"),
    url("/fonts/FIFAnybody-CondensedRegular.ttf") format("truetype"),
    url("/fonts/FIFAnybody-CondensedRegular.svg#FIFAnybody-CondensedRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FIFAnybody Condensed";
  src: url("/fonts/FIFAnybody-CondensedBold.eot");
  src: url("/fonts/FIFAnybody-CondensedBold.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/FIFAnybody-CondensedBold.woff2") format("woff2"),
    url("/fonts/FIFAnybody-CondensedBold.woff") format("woff"),
    url("/fonts/FIFAnybody-CondensedBold.ttf") format("truetype"),
    url("/fonts/FIFAnybody-CondensedBold.svg#FIFAnybody-CondensedBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/// mq() - media query manager
/// @see vendor/mq
/// @link https://github.com/sass-mq/sass-mq
$mq-breakpoints: $breakpoints;
$mq-base-font-size: 16px;
$mq-show-breakpoints: ();

$windowWidth: 1920;
$windowHeight: 1080;

$cdnUrl: "";

/// Fonts
/// @TODO: choose color variable naming convention
$roboto: "Roboto Condensed", sans-serif;
$fifa: "FIFAnybody Condensed", sans-serif;

$font-size: 1.6rem;
$font-primary: $roboto;
$font-heading: "UTM HelvetIns", sans-serif;

$golden: #cdbe91;
$golden-light: #f0e6d2;

/// Colors
$blue: #285366;
$blue-dark: #6396b1;
$blue-light: #6f8eab;
$green: #16efb5;
$orange: #ff7922;
$red: #ed2024;
$pink: #e70b51;
$yellow: #fff000;
$purple: #1c213f;

$white: #fff;
$gray-light: #e5e5e5;
$gray: #7e7e7e;
$gray-dark: #333;
$black: #000;
$smoke: #7f868a;

$color-default: $golden; //default text color

$color-primary: $golden;
$color-primary-light: $golden-light;
$color-primary-dark: $golden;

$color-secondary: $green;
$color-secondary-light: lighten($green, 10%);
$color-secondary-dark: darken($green, 10%);

$color-success: green;
$color-warning: orange;
$color-alert: red;

/// Form
$form-height: 40px;

/// Dimensions - Widths and heights
$height-header: 100px; //example!
$height-footer: 100px; //example!
