html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: tovw(18);
  color: #fff;
  font-family: $roboto;
  background-color: #000;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: #fff;
  transition: color 0.25s ease;
  outline: none !important;
  &:hover {
    color: #000;
    text-decoration: none;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  max-width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  &--content {
    max-width: tovw(1280) !important;
  }
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.swal2-shown,
.ReactModal__Body--open {
  #root {
    //filter: blur(5px);
  }
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.6) !important;
  overflow-y: auto !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
    height: auto !important;
    width: tovw(568) !important;
    min-height: tovw(300) !important;
    background: url(/images/popup.png) !important;
    background-size: 100% 100% !important;
    padding-top: tovw(100);
    padding-bottom: 2rem;
    border-radius: 0rem !important;
    .swal2-close,
    .close {
      position: absolute;
      width: tovw(27);
      height: tovw(27);
      background: url(#{$cdnUrl}/images/close.png) no-repeat center;
      background-size: 100%;
      top: 10px;
      right: 10px;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #000 !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: tovw(20);
      color: #000000;
      line-height: 150%;
      text-transform: uppercase;
      p {
        font-size: tovw(20);
        color: #000000;
        line-height: 150%;
        text-transform: uppercase;
        font-family: $fifa;
        font-weight: 500;
      }
    }
    #swal2-title,
    h2 {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: tovw(40);
      color: #fff;
      font-weight: 600;
      font-family: $fifa;
      text-transform: uppercase;
      justify-content: center;
      width: 100%;
      height: tovw(76);
      line-height: tovw(82);
      background: url(/images/title-popup.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: tovw(-5);
      left: 0;
      text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
        -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
        -1px 0px 0 #d50909, 0px -1px 0 #d50909;
    }
    &.popup-image {
      background: transparent !important;
      box-shadow: none !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 0.5vw !important;
      overflow: hidden;
      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        backface-visibility: hidden;
      }
    }
  }
}
.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 1060 !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  width: tovw(568) !important;
  min-height: tovw(300) !important;
  background: url(/images/popup.png) !important;
  background-size: 100% 100% !important;
  border-radius: 0rem !important;
  border: 0 !important;
  padding: tovw(100) 0 2rem 0 !important;
  text-align: center;
  outline: none;
  .modal-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  &.modal-vote {
    h2 {
      font-size: 3rem;
    }
  }
  &.modal-predict {
    width: tovw(688) !important;
  }
  &.modal-history {
    width: tovw(1160) !important;
    min-height: tovw(790) !important;
    .modal-description {
      width: 94%;
    }
  }
  &.modal-answer-player {
    width: tovw(1165) !important;
    min-height: tovw(835) !important;
    .modal-description {
      width: 90%;
    }
    h3 {
      font-size: tovw(30);
      color: #6f0270;
      font-weight: 600;
      font-family: $fifa;
      text-transform: uppercase;
      padding-bottom: tovw(20);
      padding-top: tovw(20);
      border-bottom: 1px solid #6f0270;
      margin-bottom: tovw(35);
    }
  }
  &.modal-answer {
    width: tovw(425) !important;
    min-height: tovw(492) !important;
    .modal-description {
      width: 80%;
    }
  }
  &.modal-info {
    width: tovw(960) !important;
    .modal-description {
      width: 94%;
      .text-italic {
        font-style: italic;
        font-size: tovw(16);
      }
      .wrapper-form {
        // background: #0d0759;
        border-top: 1px solid #000;
        padding: tovw(20) tovw(40);
        margin-top: tovw(15);

        p {
          margin-bottom: tovw(15);
          color: #000;
          text-transform: uppercase;
          font-family: $fifa;
          font-weight: 500;
          font-size: tovw(22);
          &.text-italic {
            text-transform: none;
            font-weight: normal;
            border-top: 1px solid #000;
            padding-top: tovw(20);
            margin-top: tovw(30);
          }
          &.text-danger {
            font-size: tovw(18);
            font-weight: normal;
            font-style: italic;
            text-transform: none;
          }
        }
        label {
          font-size: tovw(21);
          color: #000;
          font-family: $fifa;
          text-transform: uppercase;
          font-weight: 600;
        }
        input[type="text"] {
          width: 100%;
          border: tovw(2) solid #7fa139;
          color: #fff;
          font-size: tovw(22);
          height: tovw(48);
          line-height: tovw(48);
          outline: none;
          background-color: #629203;
          padding: tovw(35);
        }
        input[type="radio"] {
          width: tovw(24);
          height: tovw(24);
          margin-right: tovw(5);
          cursor: pointer;
        }
        .row-size {
          margin-top: tovw(20);
        }
        .info-size {
          display: flex;
          .item {
            margin-left: tovw(45);
            display: flex;
            align-items: center;

            label {
              font-style: normal;
              margin-bottom: 0;
              color: #000;
              font-weight: normal;
              cursor: pointer;
            }
          }
        }
      }
      .info-footer {
        padding: tovw(15) 0 0 10%;
      }
    }
  }
  .close {
    position: absolute;
    width: tovw(27);
    height: tovw(27);
    background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: 10px;
    right: 10px;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #000 !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: tovw(40);
    color: #fff;
    font-weight: 600;
    font-family: $fifa;
    text-transform: uppercase;
    justify-content: center;
    width: 100%;
    height: tovw(76);
    line-height: tovw(76);
    background: url(/images/title-popup.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: tovw(-5);
    left: 0;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  p,
  li {
    font-size: tovw(20);
    color: #000;
  }
}
#main-body {
  position: relative;
}
.main-content {
  position: relative;
  // background: url(/images/fullbg.jpg) no-repeat top center;
  background-size: cover;
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: tovw(10) !important;
  right: tovw(-35) !important;
  z-index: 1;
  background: transparent !important;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #0e0e0e;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  .modal-history & {
    right: 0 !important;
    &:before {
      background: #0e0e0e;
    }
  }
}
.ScrollbarsCustom-Thumb {
  width: 100% !important;
  border-radius: 0.5vw !important;
  background: #d50909 !important;
  .modal-history & {
    background: #d50909 !important;
  }
}
#header {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
#footer {
  position: relative;
  background: #000;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  .container {
    width: tovw(1320);
  }
  .footer-logo {
    display: inline-block;
    width: tovw(226);
  }
  .age {
    display: inline-block;
    width: tovw(142);
  }
  .col:nth-child(2) {
    flex: 0 0 tovw(910);
    max-width: tovw(910);
  }
  p {
    font-size: tovw(11);
    text-transform: uppercase;
    color: #6a6a6a;
    text-align: justify;
    margin-bottom: 0;
  }
}

.top-bar {
  position: relative;
  height: tovw(70);
  background: url(/images/header-bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  > .container {
    height: 100%;
    > .row {
      height: 100%;
    }
    .col-5 {
      padding-left: tovw(70);
    }
  }
  span {
    display: inline-block;
    color: #000000;
    font-size: tovw(24);
    em {
      width: 1px;
      height: 1vw;
      background: #000000;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
    }
  }
}
.user-info {
  color: #000000 !important;
  strong {
    color: #000000;
  }
}
.nickname {
  display: inline-block;
  max-width: 4vw;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logo {
  display: inline-block;
  // height: tovw(47);
  width: tovw(304);
  position: absolute;
  top: 50%;
  left: tovw(255);
  transform: translateY(-50%);
  img {
    display: block;
    backface-visibility: hidden;
    height: auto;
    width: 100%;
  }
}
.menu-link {
  display: inline-block;
  font-size: tovw(24);
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
    -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
    -1px 0px 0 #d50909, 0px -1px 0 #d50909;
}
.btn-login {
  display: inline-block;
  width: tovw(131);
  height: tovw(41);
  line-height: tovw(41);
  background: url(/images/btn-header.png) no-repeat;
  background-size: 100% 100%;
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 0.5vw;
  transition: all 0.3s ease;
  color: #fff;
  text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
    -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
    -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  &:hover {
    transform: scale(0.95);
    color: #fff;
  }
}

.btn-logout {
  display: inline-block;
  width: tovw(131);
  height: tovw(41);
  line-height: tovw(41);
  background: url(/images/btn-header.png) no-repeat;
  background-size: 100% 100%;
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 0.5vw;
  color: #fff;
  text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
    -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
    -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  &:hover {
    color: #fff;
  }
}

.section {
  position: relative;
  &--1 {
    // background: url(/images/section1.jpg) no-repeat top center;
    // background-size: cover;
    // padding-top: tovw(65);
    // padding-bottom: 4vw;
    .title-text {
      img {
        width: tovw(979);
      }
    }
  }
  &--2 {
    background: url(/images/section2.jpg) no-repeat bottom center;
    background-size: 100% 100%;
    padding: tovw(50) 0 tovw(160) 0;
    margin-top: -1px;
    min-height: tovw(1078);
  }
  &--3 {
    background: url(/images/section3.jpg) no-repeat top center;
    background-size: 100% 100%;
    padding: tovw(30) 0 tovw(140) 0;
    .sub-title {
      color: #000;
    }
  }
  &--4 {
    background: url(/images/section4.jpg) no-repeat top center;
    background-size: 100% 100%;
    padding: tovw(50) 0;
    margin-top: -1px;
    .sub-title {
      color: #000;
      em {
        display: inline-block;
        text-align: left !important;
        margin-left: tovw(-130);
      }
    }
  }
  &--5 {
    background: url(/images/section5.jpg) no-repeat;
    background-size: 100% 100%;
    padding: tovw(50) 0;
    .title-top {
      margin-top: 0;
    }
    .sub-title {
      color: #fff;
    }
  }
}
.title-main {
  padding-top: 2vw;
  @include min-screen(1200px) {
    display: none;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: tovw(863);
    max-height: 863px;
    backface-visibility: hidden;
    animation: appearing 25s infinite linear;
  }
}
.title-text {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    backface-visibility: hidden;
    width: tovw(1155);
  }
  &--1 {
    img {
      height: tovw(260);
      max-height: 260px;
    }
  }
}
.sub-title {
  color: #0b1838;
}
.wrap-spin {
  padding: tovw(30) tovw(60);
  position: relative;
  // background: rgba(111, 2, 112, 0.2);
  margin-bottom: tovw(10);
  margin-top: tovw(25);
  .col-8 {
    flex: 0 0 62.5%;
  }
  .col-4 {
    padding: 0 tovw(50);
    > div {
      margin-bottom: tovw(12);
    }
    .text-red {
      vertical-align: middle;
      display: inline-block;
      font-size: tovw(26);
    }
    p {
      font-size: tovw(23);
      text-transform: uppercase;
      color: #fff;
    }
  }
}
.col {
  &--item-spin {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 2rem;
    .item-cover {
      background: #2f4e6f;
      box-shadow: 2.121px 2.121px 5px 0px rgba(174, 127, 31, 1);
      padding-top: tovw(10);
    }
    // &:nth-child(odd) {
    //   .item-cover {
    //     background: #B8DFD2;
    //     background: -webkit-linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
    //     background: -moz-linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
    //     background: linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
    //     box-shadow: 2.121px 2.121px 5px 0px rgba(174, 127, 31, 1);
    //     // border: 1px solid #4f53ff;
    //   }
    // }
  }
  &--item-exchange {
    flex: 0 0 16.66%;
    max-width: 16.66%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 5rem;
    > div {
      justify-content: center;
      display: flex;
      flex-direction: column;

      .btn {
        margin: tovw(20) auto 0 auto;
        font-size: tovw(24);
        font-family: $fifa;
        font-weight: 500 !important;
        min-width: tovw(194) !important;
      }
    }
    .item-cover {
      background: #fff;
      border: 0;
      box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.16);
      &--limiteds {
        background: url(/images/limiteds.png) no-repeat !important;
        background-size: 100% 100% !important;
        box-sizing: border-box;
        .count-exchange {
          color: #161349;
          font-weight: bold;
        }
      }
      &--speical {
        background: url(/images/limiteds.png) no-repeat !important;
        background-size: 100% 100% !important;
        box-sizing: border-box;
        .count-exchange {
          color: #161349;
          font-weight: bold;
        }
      }
    }
    // &:nth-child(4),
    // &:nth-child(5),
    // &:nth-child(6) {
    //   .item-cover {
    //     background: #b20e09;
    //     border: 2px solid #f1c490;
    //     box-sizing: border-box;
    //   }
    //   .count-exchange {
    //     color: #fff;
    //   }
    // }
  }

  &--accumulate {
    &:nth-child(1) {
      text-align: left;
      padding-left: 0;
    }
    &:nth-child(3) {
      flex: 0 0 22.5%;
    }
    &:nth-child(5) {
      text-align: right;
      padding-right: 0;
    }
  }
  &--team-list {
    flex: 0 0 41%;
    max-width: 41%;
    padding-left: tovw(10);
    padding-right: tovw(10);
  }
  &--team-detail {
    flex: 0 0 59%;
    max-width: 59%;
  }
  &--team-name {
    padding-left: tovw(90);
    flex: 0 0 tovw(340);
    max-width: tovw(340);
  }
  &--team-trophy {
    padding-left: tovw(100);
    text-align: left;
    width: 100%;
    // flex: 0 0 tovw(540);
    // max-width: tovw(540);

    ul {
      overflow: hidden;
      width: 100%;
      li {
        float: left;
        width: 50%;
      }
    }
  }
}
.item-cover {
  display: inline-block;
  width: tovw(128);
  height: tovw(128);
  // background: #460086;
  // border: 1px solid #c600ff;
  position: relative;
  &--exchange {
    width: tovw(190);
    height: tovw(190);
    margin: 0 auto;
    background: url(/images/exchange.png) no-repeat !important;
    background-size: 100% 100% !important;

    .count-exchange {
      position: absolute;
      top: 0.2vw;
      right: 0.3vw;
      color: #161349;
      font-weight: bold;
    }
    .server-limit {
      position: absolute;
      top: 0.2vw;
      left: 0.3vw;
      color: #161349;
    }
  }
  &--limited {
    border: 1px solid #feefac !important;
    .count-limited {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: tovw(106);
      height: tovw(56);
      background: url(/images/icon-limited.png) no-repeat;
      background-size: cover;
    }
  }
  &__img {
    height: 80%;
    top: 10%;
    position: absolute;
    backface-visibility: hidden;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.25s ease;
    transform-origin: left;
    z-index: 2;
    &--exchange {
      height: 75%;
      top: 14%;
    }
  }
  &:hover {
    img {
      transform: scale(1.05) translateX(-50%);
    }
  }
  &:before {
    content: "";
    width: 126%;
    height: 128%;
    position: absolute;
    top: -14%;
    left: -14%;
    background: url(/images/spin-active.png) no-repeat;
    background-size: 100% 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  &:after {
    content: "";
    width: 112%;
    height: 112%;
    position: absolute;
    top: -6%;
    left: -6%;
    background: url(/images/border-spin-active.png) no-repeat;
    background-size: 100% 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  &--active {
    z-index: 1;
    &:before,
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
  &--win {
    animation: won 1.5s;
  }
}
.title {
  position: absolute;
  white-space: nowrap;
  bottom: 102%;
  left: 50%;
  transform: translateX(-50%);
  font-size: tovw(15);
  padding: tovw(5);
  border-radius: tovw(5);
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease, visibility 0.25s ease;
  z-index: 10;
}
.icon-faq {
  display: inline-block;
  width: tovw(20);
  vertical-align: middle;
  img {
    display: block;
    height: 100%;
    animation: heartBeat 1.5s ease infinite;
  }
}
.wrap-accumulate {
  position: relative;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  &:before {
    content: "";
    background: url(/images/dock.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: tovw(1800);
    height: tovw(320);
    left: 50%;
    transform: translateX(-50%);
    bottom: tovw(-34);
    display: none;
  }
  .progress-line {
    position: absolute;
    top: 68.5%;
    left: 10%;
    background-color: #161349;
    .progress-value {
      background: #ffab44;
    }
  }
  .w-100 {
    padding-bottom: tovw(135);
  }
  .txt-accumulated-point {
    width: tovw(341);
    height: tovw(64);
    line-height: tovw(64);
    margin: tovw(25) auto 0 auto;
    color: #fff;
    font-weight: bold;
    background: url(/images/accumulated-point.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    text-shadow: 0px tovw(3) tovw(4) rgba(207, 8, 12, 0.4);
    .text-green {
      color: #fff !important;
      font-size: tovw(26);
      line-height: tovw(26);
      font-weight: bold;
    }
  }
}
.item-single {
  position: relative;
  display: inline-block;
  width: tovw(192);
  z-index: 1;
  text-align: center;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: tovw(20);
    background: url("/images/shadow-item.png") no-repeat;
    background-size: 100% 100%;
    bottom: tovw(-12);
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  }
  img {
    display: inline-block;
    // width: 100%;
    height: tovw(135);
  }
  .progress-point {
    position: absolute;
    top: 115%;
    left: 50%;
    margin-left: tovw(-39.5);
    color: #acce00;
    .txt-point {
      background: #ef74a5;
      background: linear-gradient(to top, #ef74a5 0%, #f6a8ba 16%, #eb2024 92%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .progress-point {
    position: absolute;
    top: 119%;
    left: 50%;
    margin-left: tovw(-39.5);
    // border-radius: 50%;
    // transform: rotate(-45deg);
    .txt-point {
      display: inline-block;
      // transform: rotate(45deg);
    }
    &::before {
      border-radius: 50%;
    }
    &::after {
      content: "";
      position: absolute;
      top: -10%;
      left: -10%;
      background-color: transparent;
      z-index: 1;
      width: 120%;
      height: 120%;
    }
    &__line {
      position: absolute;
      width: 8.5vw;
      height: 0.26042vw;
      background: #000000;
      left: 107%;
      // transform: rotate(45deg);
      transform-origin: bottom left;
      top: 50%;
      transform: translateY(-50%);
      &--passed {
        background-color: #689507;
      }
    }
  }
}
.icon-faq-star {
  display: inline-block;
  position: relative;
  span {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
}
.item-cover:hover,
.item-single:hover,
.prize:hover,
.icon-faq-star:hover {
  .title {
    opacity: 1;
    visibility: visible;
  }
}
.progress-point {
  display: inline-block;
  width: tovw(69);
  height: tovw(87);
  // border-radius: 50%;
  // background: #000000;
  background: url(/images/polygon.png) no-repeat;
  background-size: 100% 100%;
  font-size: tovw(30);
  font-family: $font-heading;
  line-height: tovw(87);
  text-align: center;
  color: #acce00;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: tovw(97);
    height: tovw(97);
    border: 1px solid #000000;
    border-radius: 50%;
    display: none;
  }
  &--passed {
    background: url(/images/polygon-active.png) no-repeat;
    background-size: 100% 100%;
    color: #050f30 !important;
    .txt-point {
      -webkit-text-fill-color: #050f30 !important;
      color: #050f30 !important;
    }
    &:before {
      // border-color: #d30a03;
    }
  }
}
.progress-line {
  height: tovw(6);
  position: relative;
  background: #fff;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  .progress-value {
    position: absolute;
    height: 100%;
    background: #6f0270;
    max-width: 100%;
  }
}
.title-team {
  text-align: center;
  img {
    display: inline-block;
    width: tovw(677);
  }
}
.team-list {
  position: relative;
  text-align: center;
  background: #fff;
  padding: tovw(15) tovw(10);
  background-image: -moz-linear-gradient(
    90deg,
    rgb(73, 174, 141) 0%,
    rgba(73, 174, 141, 0.6) 20%,
    rgba(73, 174, 141, 0.13) 87%,
    rgba(73, 174, 141, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(73, 174, 141) 0%,
    rgba(73, 174, 141, 0.6) 20%,
    rgba(73, 174, 141, 0.13) 87%,
    rgba(73, 174, 141, 0) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(73, 174, 141) 0%,
    rgba(73, 174, 141, 0.6) 20%,
    rgba(73, 174, 141, 0.13) 87%,
    rgba(73, 174, 141, 0) 100%
  );
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.16);
  border-radius: tovw(20);
  h3 {
    font-size: tovw(28);
    color: #161349;
    font-family: $font-heading;
    text-transform: uppercase;
    margin-bottom: tovw(10);
    // font-style: italic;
    margin-bottom: tovw(30);
  }
  .row {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
  .team-cover {
    &:before {
      background: url(/images/team-active.png) no-repeat;
      background-size: 100% 100%;
    }
    &:after {
      background: url(/images/border-team-active.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
.hr {
  width: 100%;
  height: 0.1vw;
  background: url(/images/tie.png) no-repeat center top;
  background-size: auto 100%;
  margin-bottom: tovw(15);
  &--white {
    background: url(/images/hr-white.png) no-repeat center top;
    background-size: 100%;
    margin-bottom: tovw(15);
  }
}
.team-cover {
  display: inline-block;
  position: relative;
  width: tovw(143);
  height: tovw(139);
  box-sizing: content-box;
  // border: 2px solid #fff;
  margin-bottom: tovw(15);
  transition: background-color 0.25s ease;
  box-shadow: 2.828px 2.828px 6px 0px rgba(174, 127, 31, 0.4);
  background-color: #2f4e6f;
  &:before {
    content: "";
    width: 133%;
    height: 134%;
    position: absolute;
    top: -17%;
    left: -16%;
    background: url(/images/spin-active.png) no-repeat;
    background-size: 100% 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  &:after {
    content: "";
    width: 118%;
    height: 118%;
    position: absolute;
    top: -9%;
    left: -8%;
    background: url(/images/border-spin-active.png) no-repeat;
    background-size: 100% 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  &__img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // transform: translateX(-50%);
    transition: transform 0.25s ease;
    // transform-origin: left;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  &--selected {
    z-index: 1;
    &:before,
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}
.team-detail {
  position: relative;
  .row {
    height: 100%;
  }
  &__img {
    position: relative;
    border: 2px solid #ffab44;
    position: relative;
    margin-bottom: 15px;
    img {
      display: block;
      width: 100%;
    }
  }
  &__list-member {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    // background: url(/images/bg-team-member.png) repeat-y top center;
    // background-size: 100% 100%;
    // border: 2px solid #0ee7f9;
    background-image: -moz-linear-gradient(
      90deg,
      rgb(73, 174, 141) 0%,
      rgba(73, 174, 141, 0.6) 40%,
      rgba(73, 174, 141, 0.13) 87%,
      rgba(73, 174, 141, 0) 100%
    );
    background-image: -webkit-linear-gradient(
      90deg,
      rgb(73, 174, 141) 0%,
      rgba(73, 174, 141, 0.6) 40%,
      rgba(73, 174, 141, 0.13) 87%,
      rgba(73, 174, 141, 0) 100%
    );
    background-image: -ms-linear-gradient(
      90deg,
      rgb(73, 174, 141) 0%,
      rgba(73, 174, 141, 0.6) 40%,
      rgba(73, 174, 141, 0.13) 87%,
      rgba(73, 174, 141, 0) 100%
    );
    box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.16);
    margin-bottom: 0.5vw;
    padding-top: 0.3vw;
    padding-bottom: 0.5vw;
  }
  &__name-member {
    width: 25%;
    text-align: center;
    padding-top: 0.2vw;
    // border-right: 1px solid #d20903;
    p {
      font-size: tovw(17);
      color: #161349;
      font-weight: bold;
      // font-style: italic;
      margin-bottom: 0;
    }
    &:last-of-type {
      border-right: 0;
    }
  }
  &__info {
    width: 100%;
    background: url(/images/team-info-bg.png) no-repeat;
    background-size: 100% 100%;
    height: tovw(140);
    text-align: center;
  }
  span {
    font-size: 15px;
    color: #161349;
    top: 3px;
    position: absolute;
    text-align: right;
    right: 3px;
    line-height: 32px;
    padding: 0 0.5vw;
    font-weight: bold;
    background: url(/images/team-selected.png) no-repeat;
    width: 148px;
    height: 32px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
ul {
  list-style: none;
  line-height: 150%;
  margin: 0;
  padding: 0;
  margin-bottom: tovw(5);
  li {
    position: relative;
    padding-left: tovw(22);
    &:before {
      content: "";
      position: absolute;
      top: tovw(8);
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: tovw(5) 0 tovw(5) tovw(10);
      border-color: transparent transparent transparent #ffab44;
    }
  }
}
.team-balance {
  padding: tovw(20) 0;
  .col-3 {
    text-align: center;
  }
  span {
    display: inline-block;
    line-height: 120%;
    vertical-align: middle;
    padding: 0 tovw(3);
    text-align: right;
    font-size: tovw(16);
    &.text-large {
      transform: translateY(tovw(-5));
      strong {
        display: inline-block;
        line-height: 100%;
        font-size: tovw(36);
      }
    }
    &.text-red {
      color: #ffab44 !important;
    }
  }
}
.title-predict {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: tovw(955);
  }
}
.predict-board {
  padding: tovw(30) tovw(100);
  .link-group--normal {
    margin-bottom: 1vw;
  }
}
.match {
  position: relative;
  background: url(/images/match-bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 0.5vw;
  min-height: tovw(135);
  margin-bottom: 1vw;
  h3 {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    margin-top: tovw(4);
    font-size: tovw(26);
    font-family: $fifa;
    font-weight: bold;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  }
  &__team-name {
    font-size: tovw(20);
    font-weight: bold;
    color: #fff;
    max-width: tovw(90);
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }
  &__team-logo {
    display: inline-block;
    width: tovw(55);
  }
  > .row {
    &:first-child {
      margin-bottom: 0.5vw;
      color: #fff;
      font-size: tovw(24);
      font-weight: 500;
      font-family: $fifa;
      padding-top: tovw(2);
      text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
        -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
        -1px 0px 0 #d50909, 0px -1px 0 #d50909;
      text-transform: uppercase;
    }
    &:last-child {
      padding-top: 1vw;
      padding-bottom: 1vw;
    }
  }
  .btn {
    width: tovw(180);
    height: tovw(50);
    line-height: tovw(50);
    min-width: initial !important;
    font-size: tovw(18);
    float: right;
    padding-right: 0;
    padding-left: 0;
  }
  &:nth-child(even) {
    background: url(/images/match-bg2.png) no-repeat;
    background-size: 100% 100%;
    .link-group {
      span {
        background-color: #d31c20;
        border-right: 2px solid #000;
      }
    }
  }
  .link-group {
  }
}
.link-group {
  position: relative;
  a,
  span {
    display: inline-block;
    width: 100%;
    background: #ffffff;
    height: tovw(35);
    border-right: 2px solid #ee4147;
    text-align: center;
    line-height: tovw(35);
    text-transform: uppercase;
    // font-style: italic;
    &:first-child {
      border-radius: tovw(35) 0 0 tovw(35);
    }
    &:last-child {
      border-radius: 0 tovw(35) tovw(35) 0;
      border-right: 0;
    }
  }
  span {
    background: #000000;
    color: #ffab44;
  }
  &--rate {
    color: #fff !important;
    font-size: tovw(22);
    font-family: $fifa;
    font-weight: 500;
  }
  &--normal {
    margin-left: 0;
    position: relative;
    background: url(/images/tab.png) no-repeat;
    background-size: 100% 100%;
    height: tovw(54);
    align-items: flex-end;
    justify-content: center;
    a,
    span {
      font-size: tovw(22);
      height: tovw(45);
      border: 1px solid transparent !important;
      line-height: tovw(45);
      border-radius: 0 !important;
      font-weight: bold;
      color: #ffffff;
      background: transparent;
      margin-bottom: tovw(3);
      text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
        -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
        -1px 0px 0 #d50909, 0px -1px 0 #d50909;
      &.active {
        background-color: #000;
      }
    }
    a {
      // margin-right: tovw(3) !important;
      // box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.16);
      flex: 0 0 calc(33.333333% - #{tovw(3)});
      max-width: calc(33.333333% - #{tovw(3)});
      // &:nth-child(1) {
      //   border-top-left-radius: tovw(10) !important;
      //   border-bottom-left-radius: tovw(10) !important;
      // }
      // &:nth-child(3) {
      //   border-top-right-radius: tovw(10) !important;
      //   border-bottom-right-radius: tovw(10) !important;
      // }
    }
  }
}
.guess {
  width: tovw(214);
  height: tovw(214);
  line-height: 1;
  background: url(/images/guess.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  font-size: tovw(22);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: tovw(120);
  left: tovw(-214);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: tovw(20);
  span {
    strong {
      color: #fff;
      background: url("/images/guess-num.png") no-repeat;
      background-size: 100% 100%;
      font-size: tovw(68);
      font-weight: bold;
      display: block;
      margin-top: tovw(10);
      text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
        -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
        -1px 0px 0 #d50909, 0px -1px 0 #d50909;
    }
  }
}
.star-of-hope {
  display: inline-block;
  width: tovw(62);
  position: absolute;
  bottom: 1.8vw;
  left: 1vw;
}
.title-top {
  margin-top: 4vw;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: tovw(1033);
  }
}
.board-top {
  width: 26.5vw;
  height: tovw(649);
  position: relative;
  text-align: center;
  padding: 1.5vw;
  background: url(/images/board.png) no-repeat;
  background-size: 100% 100%;
  h3 {
    font-family: $font-heading;
    color: $orange;
    text-shadow: 0 0.2vw 0 #6a09ff;
    font-size: tovw(36);
    text-transform: uppercase;
    margin-bottom: 1vw;
  }
  .ScrollbarsCustom-Track {
    right: 0 !important;
  }
}
.mb0 {
  margin-bottom: 0 !important;
}
.table-top {
  width: 100%;
  margin-bottom: 0.5vw;
  th {
    font-weight: normal;
    font-size: tovw(24);
    font-family: $font-heading;
    padding: 0.1vw;
  }
  td {
    padding: 0.48vw 0.2vw;
  }
}
.prize {
  position: absolute;
  img {
    display: block;
    width: 100%;
    animation: heartBeatLow 1.5s ease infinite;
  }
  &--1 {
    width: tovw(274);
    height: tovw(300);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &--2 {
    width: tovw(225);
    height: tovw(246);
    top: 40%;
    left: 5%;
    img {
      animation-delay: 0.2s;
    }
  }
  &--3 {
    width: tovw(225);
    height: tovw(246);
    top: 40%;
    right: 5%;
    img {
      animation-delay: 0.4s;
    }
  }
  &--4 {
    width: tovw(187);
    height: tovw(204);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      animation-delay: 0.6s;
    }
  }
}
.match-progress {
  text-align: left;
  p {
    font-weight: bold;
    text-transform: uppercase !important;
    color: #000 !important;
  }
  img {
    display: inline-block;
    height: tovw(37);
    backface-visibility: hidden;
  }
  .play-progress-share {
    margin-left: 1px;
  }
  .w-100 {
    margin-bottom: 2rem;
  }
  .col-5 {
    text-align: right;
  }
}
.pred {
  .col-4 {
    padding-right: 0;
    padding-left: 0;
    .btn {
      width: 98% !important;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
.icon-star-small {
  width: tovw(16);
  display: inline-block;
  vertical-align: middle;
}
.icon-star-medium {
  width: tovw(22);
  display: inline-block;
  vertical-align: middle;
}
.checkbox-wrap {
  font-size: tovw(18);
  color: #d4120b !important;
  cursor: pointer;
}
.checkbox {
  display: inline-block;
  vertical-align: middle;
  width: tovw(22);
  height: tovw(22);
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 0.8rem;
  transform: translateY(-0.1vw);
  &:before {
    content: "";
    position: absolute;
    width: tovw(12);
    height: tovw(12);
    background: $red;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-top: tovw(-6);
    margin-left: tovw(-6);
    transition: transform 0.15s ease;
    transform: scale(0);
  }
  &--active {
    &:before {
      transform: scale(1);
    }
  }
}
.result-active {
  background: #ffab44 !important;
  color: #161349 !important;
}
.btn-gift {
  position: absolute;
  width: tovw(113);
  top: tovw(-50);
  right: 0;
  img {
    display: block;
    width: 100%;
    backface-visibility: hidden;
    animation: ping 2s infinite;
  }
}
.title-share {
  text-align: center;
  img {
    display: inline-block;
    width: tovw(776);
  }
}
.share-milestone {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: tovw(1040);
  height: tovw(554);
  // background: url(/images/share-bg.png) no-repeat;
  // background-size: 100% 100%;
  padding-top: 5vw;
  .progress-line {
    position: absolute;
    top: 56.7%;
    width: 56%;
    left: 22%;
    pointer-events: none;
    background: #161349;
  }
  .progress-point {
    position: relative;
    pointer-events: none;
    // background-color: #fff;
    color: #fff;
    &::before {
      border: 1px solid #fff;
    }
    &--passed {
      background: url(/images/polygon-active.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
      &:before {
        border-color: #689507;
      }
    }
  }
  p {
    color: #6f0270;
  }
}
.milestone {
  position: relative;
  a {
    &:hover {
      .milestone__point {
        color: #6f0270 !important;
      }
    }
  }
  img {
    display: inline-block;
    width: tovw(205);
  }
  &__point {
    position: absolute;
    font-size: 3vw;
    font-family: $font-heading;
    top: 16%;
    left: 47%;
    color: #161349;
    &--active {
      color: #6f0270;
    }
  }
}
.milestone-wrap {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.col-progress-point {
  margin-top: -5.6vw;
  z-index: 1;
  pointer-events: none;
}
.table-history {
  width: 100%;
  tr {
    th,
    td {
      font-size: tovw(20);
      color: #000;
    }
    th {
      font-weight: bold;
      color: #ed2024;
      text-transform: uppercase;
      font-size: tovw(26);
      padding-bottom: tovw(25);
    }
    td {
      padding: tovw(20) 0;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #fff;
        background-image: -moz-linear-gradient(
          left,
          rgb(235, 32, 36) 15%,
          rgb(239, 116, 165) 100%
        );
        background-image: -webkit-linear-gradient(
          left,
          rgb(235, 32, 36) 15%,
          rgb(239, 116, 165) 100%
        );
        background-image: -ms-linear-gradient(
          left,
          rgb(235, 32, 36) 15%,
          rgb(239, 116, 165) 100%
        );
        td {
          color: #fff;
        }
      }
    }
  }
}
.btn-his {
  display: inline-block;
  height: tovw(47);
  display: inline-block;
  font-size: tovw(28);
  line-height: tovw(47);
  text-transform: uppercase;
  color: #fff !important;
  font-family: $fifa;
  font-weight: bold;
  margin-bottom: tovw(3);
  text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
    -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
    -1px 0px 0 #d50909, 0px -1px 0 #d50909;
  flex: 0 0 calc(50% - #{tovw(3)});
  max-width: calc(50% - #{tovw(3)});
  &--active {
    background: #050f30;
  }
}
.wrap-video {
  width: 100%;
  position: relative;
  z-index: 1;
  video {
    width: 100%;
    display: block;
    @include max-screen(1200px) {
      display: none;
    }
  }
  img {
    width: 100%;
    display: block;
    @include min-screen(1200px) {
      display: none;
    }
  }
  .play-now {
    position: absolute;
    bottom: tovw(80);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: tovw(300);
    height: tovw(54);
    line-height: tovw(54);
    transition: all 0.3s ease;
    transform-origin: top left;

    a {
      display: inline-block;
      font-weight: bold;
      text-transform: uppercase;
    }
    img {
      display: inline-block;
      width: 100%;
    }
    &:hover {
      transform: scale(0.95) translateX(-50%);
      a {
        color: #fff;
      }
    }
  }
  .decor {
    position: absolute;
    bottom: tovw(-1);
    left: 0;
    width: 100%;
    height: tovw(55);
    z-index: 2;
    background: url(/images/decor.png) no-repeat;
    background-size: 100% 100%;
  }
}

.count-down {
  margin-bottom: tovw(40);
  .time-count-down {
    padding-left: 10px;
    text-transform: uppercase;
  }
  .item-time {
    display: inline-block;
    margin-right: 5px;

    span {
      display: inline-block;
      width: tovw(22);
      height: tovw(33);
      line-height: tovw(31);
      background: url(/images/bg-time.png) no-repeat;
      background-size: cover;
      color: #000000;
      font-size: tovw(30);
      text-align: center;
    }
  }
  .prediction-score {
    text-transform: uppercase;
    text-align: center;
    padding-right: 10px;
    width: tovw(360);
    height: tovw(56);
    line-height: tovw(56);
    background: url(/images/prediction-score.png) no-repeat;
    background-size: 100% 100%;
    font-size: tovw(18);
    color: #fff;
    font-weight: bold;
    margin: 0 auto tovw(30) auto;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
    span {
      font-size: tovw(24);
      font-weight: bold;
      color: #fff;
      text-shadow: none;
      text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
        -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
        -1px 0px 0 #d50909, 0px -1px 0 #d50909;
    }
    img {
      display: inline-block;
      width: tovw(28);
      margin-top: tovw(-12);
      margin-left: tovw(3);
      cursor: pointer;
    }
  }
}

.modal-info {
}
.section1-spin {
  background: url(/images/section1.jpg) no-repeat;
  background-size: 100% 100%;
  padding-top: tovw(60);
  padding-bottom: tovw(95);
}
.title-question {
  margin-bottom: tovw(30);
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: tovw(1070);
  }
}
.question {
  padding-top: tovw(230);
  padding-bottom: tovw(10);
  .col-9 {
    color: #000;
  }
  p {
    margin-bottom: tovw(30);
    em {
      display: inline-block;
      text-align: left;
      padding-left: tovw(22);
    }
  }
  &__actions {
    margin-bottom: tovw(40);
    a ~ a {
      margin-left: tovw(20);
    }
  }
  &__item {
    position: relative;
    width: tovw(250);
    margin: 0 auto tovw(25) auto;
    text-align: center;
    p {
      font-size: tovw(14);
      color: #000000;
    }
    &.active,
    &:hover {
      .question__img {
        // border: tovw(2) solid #fcb700;
        transform: scale(0.94);
      }
    }
  }
  &__img {
    position: relative;
    margin: 0 auto tovw(15) auto;
    transition: transform 0.3s linear;
    width: tovw(203);
    height: tovw(203);
    background: url(/images/question-img.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 94%;
      height: 94%;
      object-fit: contain;
    }
  }
  &__point {
    position: absolute;
    top: tovw(10);
    right: tovw(8);
    font-size: tovw(14);
    font-weight: bold;
    color: #ed2024;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/images/polygon-white.png) no-repeat;
    background-size: 100% 100%;
    width: tovw(37);
    height: tovw(48);
    line-height: 1.1;
    text-transform: uppercase;
  }
  &__answer-select {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: tovw(18);
    width: 100%;
    padding: 0 tovw(15);
  }
}
.video-live {
  width: tovw(770);
  height: tovw(440);
  margin: tovw(40) auto 0 auto;
}
.player {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__item {
    width: 20%;
    text-align: center;
    padding: 0 tovw(10);
    margin-bottom: tovw(20);
    cursor: pointer;
    height: tovw(190);
    &.active,
    &:hover {
      .player__img {
        background: url(/images/player-active.png) no-repeat;
        background-size: 100% 100%;
        span {
          // &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__img {
    display: flex;
    position: relative;
    background: url(/images/player.png) no-repeat;
    background-size: 100% 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: 80%;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: tovw(-4);
      bottom: tovw(-4);
      left: tovw(-4);
      right: tovw(-4);
      border-radius: tovw(2);
      border: tovw(2) solid #fff;
      opacity: 0;
      visibility: hidden;
      transition: opacity 3s linear visibility 3s linear;
    }
    span {
      position: absolute;
      top: tovw(10);
      right: tovw(8);
      width: tovw(37);
      height: tovw(46);
      background: url(/images/polygon-white.png) no-repeat;
      background-size: 100% 100%;
      font-size: tovw(10);
      text-transform: uppercase;
      color: #ed2024;
      font-family: $fifa;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      transition: opacity 3s linear visibility 3s linear;
    }
  }
}
.player-actions {
  margin-top: tovw(15);
  .btn--purple {
    margin-right: tovw(20);
  }
}
.answer {
  padding-bottom: tovw(15);
  border-bottom: 1px solid #050f30;
  margin-bottom: tovw(25);
  &__item {
    display: block;
    min-height: tovw(56);
    line-height: tovw(56);
    background: url(/images/btn-white-long.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-size: tovw(24);
    text-transform: uppercase;
    color: #fff;
    margin-bottom: tovw(15);
    transition: all 0.3s linear;
    text-shadow: 1px 1px 0 #d50909, 1px -1px 0 #d50909, -1px 1px 0 #d50909,
      -1px -1px 0 #d50909, 1px 0px 0 #d50909, 0px 1px 0 #d50909,
      -1px 0px 0 #d50909, 0px -1px 0 #d50909;
    &.active,
    &:hover {
      background: url(/images/btn-purple-long.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
  }
}
.predict-area {
  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: tovw(30) 0;
    // border-bottom: 1px solid #6f0270;
    // border-top: 1px solid #6f0270;
    margin-bottom: tovw(5);
  }
  &__team {
    width: 50%;
    text-align: center;
  }
  &__logo {
    width: tovw(130);
    height: tovw(130);
    margin: 0 auto tovw(30) auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  &__rate {
    width: tovw(123);
    margin: 0 auto tovw(30) auto;
    color: #fff;
    font-size: tovw(18);
    text-transform: uppercase;
    font-family: $fifa;
    font-weight: 500;
    height: tovw(43);
    line-height: tovw(43);
    background: url(/images/rate.png) no-repeat;
    background-size: 100% 100%;
  }
  &__btn-option {
    display: inline-block;
    width: tovw(200);
    height: tovw(56);
    line-height: tovw(56);
    outline: none;
    font-size: tovw(24);
    font-family: $fifa;
    font-weight: 500;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    background: url(/images/btn-red-big.png) no-repeat;
    background-size: 100% 100%;
    transition: all 0.3s linear;
    text-shadow: 1px 1px 0 #77b200, 1px -1px 0 #77b200, -1px 1px 0 #77b200,
      -1px -1px 0 #77b200, 1px 0px 0 #77b200, 0px 1px 0 #77b200,
      -1px 0px 0 #77b200, 0px -1px 0 #77b200;
    &.active,
    &:hover {
      background: url(/images/btn-white.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--label {
      color: #000000;
      font-size: tovw(24);
      font-family: $fifa;
      font-weight: 600;
      text-transform: uppercase;
    }
    &--input {
      input {
        outline: none;
        display: inline-block;
        width: tovw(195);
        height: tovw(55);
        line-height: tovw(55);
        background: url(/images/bg-input.png) no-repeat;
        background-size: 100% 100%;
        color: #fff;
        font-size: tovw(22);
        font-family: $fifa;
        font-weight: 500;
        padding: 0 tovw(8);
        border: 0;
      }
    }
    &--btn {
      .btn {
        min-width: tovw(200) !important;
      }
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
    font-style: italic;
    font-weight: normal;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
    font-style: italic;
    font-weight: normal;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
    font-style: italic;
    font-weight: normal;
  }
}
.btn-his-wrap {
  width: tovw(750);
  margin-left: auto;
  margin-right: auto;
  height: tovw(54);
  background: url(/images/title-popup.png) no-repeat;
  background-size: 100% 100%;
  align-items: flex-end;
  margin-bottom: tovw(40);
}
